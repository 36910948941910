<template>
  <div class="page-classroom-view">
    <div class="my-page">
      <div class="page-user-noticeview">
        <div class="my-page">
          <h2>{{ viewData.Title }}</h2>
          <div class="notice-des">
            <span>{{ viewData.CreateUserName }}发布</span>
            <span class="time">
              <van-icon name="clock-o" />
              {{ viewData.CreateDate }}
            </span>
          </div>
          <div class="notice-content" v-html="viewData.Content"></div>
          
        </div>
      </div>
      <div class="notice-file">
            <span>附件下载</span>
            <div class="" v-for="(item, i) in viewData.accessoryList" :key="i">
              {{ item.name }}
              <a @click="review(item)">下载</a>
            </div>
          </div>
      <!-- 按钮组 -->
      <div
        v-if="viewData.AuditState == '未审核'"
        class="btn-box"
        style="margin-top: 20px"
      >
        <van-button
          type="warning"
          style="width: 40%; float: left"
          @click="dialogVisible = true"
          >驳回</van-button
        >
        <van-button
          color="#47AFA7"
          type="info"
          style="width: 40%; float: right"
          @click="handlePass"
          >通过</van-button
        >
      </div>
      <!-- 驳回弹窗 -->
      <van-dialog
        v-model="dialogVisible"
        title="驳回"
        :beforeClose="beforeClose"
        showCancelButton
      >
        <van-field
          required
          v-model="AuditDesc"
          name="LeaveDesc"
          rows="2"
          label="驳回原因"
          type="textarea"
          placeholder="请输入驳回原因"
        />
      </van-dialog>
    </div>
  </div>
</template>
    
    <script>
import {
  Icon,
  Button,
  Form,
  Field,
  Uploader,
  Popup,
  Picker,
  DatetimePicker,
  Dialog,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Tag,
  Toast,
} from "vant";
export default {
  components: {
    [Icon.name]: Icon,
    [Form.name]: Form,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [Dialog.Component.name]: Dialog.Component,
    [Toast.name]: Toast,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Tag.name]: Tag,
  },
  data() {
    return {
      minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      id: null,
      proclamationID: null,
      viewData: {
        AccessoryPostList: [],
        flowList: [],
      },
      leaveTypeList: [],
      showPicker: false,
      showTime: false,
      showTime2: false,
      loading: false,
      dialogVisible: false,
      AuditDesc: "",
      activeNames: ["1"],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.proclamationID = this.$route.query.proclamationID;
      this.getDetail();
      this.getEnum();
    }
  },
  methods: {
    getEnum() {
      this.$axios.get("/api/Base/GetEnum?type=LeaveType").then((res) => {
        if (res.code === 200) {
          this.leaveTypeList = res.data.List;
        }
      });
    },
    getDetail() {
      this.$axios
        .get("/api/Proclamation/Get", {
          ID: this.proclamationID,
        })
        .then((res) => {
          if (res.code === 200) {
            this.viewData = {
              ...res.data,
              AccessoryPostList: res.data.accessoryList,
              flowList: res.data.auditHistoryList,
            };
          }
        });
    },
    onConfirm(value) {
      this.viewData.LeaveTypeName = value.EnumName;
      this.viewData.LeaveType = value.EnumValue;
      this.showPicker = false;
    },
    onConfirmTime(value) {
      this.viewData.BeginDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime = false;
      // 计算总时长
      this.sumTime();
    },
    onConfirmTime2(value) {
      this.viewData.EndDate = new Date(value).format("yyyy-MM-dd hh:mm");
      this.showTime2 = false;
      // 计算总时长
      this.sumTime();
    },
    sumTime() {
      const startTime = this.viewData.BeginDate;
      const endTime = this.viewData.EndDate;
      if (startTime && endTime) {
        this.$set(
          this.viewData,
          "LeaveTimeCount",
          this.$moment(endTime)
            .diff(this.$moment(startTime), "hours", true)
            .toFixed(1)
        );
      }
    },
    beforeRead(file) {
      Toast.loading({
        message: "加载中...",
        duration: 0,
      });
      let formData = new FormData();
      formData.append("LastFile", file);
      formData.append("FileName", file.name);
      this.$axios.upload("/api/File/UploadFile", formData).then((res) => {
        Toast.clear();
        if (res.code === 200) {
          this.viewData.AccessoryPostList.push({
            name: file.name,
            osskey: res.data.osskey,
            uploadUrl: res.data.uploadUrl,
          });
          return true;
        } else {
          this.$toast.fail(res.msg || "操作失败");
          return false;
        }
      });
    },
    review(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    save() {
      let that = this;
      this.$axios.post("/api/WorkerLeave/Add", this.viewData).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg || "操作成功");
          setTimeout(() => {
            that.$router.replace({
              path: "LeaveTeacher",
              query: {
                active: 0,
              },
            });
          }, 1000);
        } else {
          this.$toast.fail(res.msg || "操作失败");
        }
      });
    },
    handlePass() {
      let that = this;
      this.$axios
        .post("/api/Proclamation/Audit", {
          AuditDesc: "通过",
          AuditID: parseInt(this.id),
          AuditState: 3,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.msg || "操作成功");
            setTimeout(() => {
              that.$router.replace("AuditSchedule");
            }, 1000);
          } else {
            this.$toast.fail(res.msg || "操作失败");
          }
        });
    },
    beforeClose(action, done) {
      let that = this;
      if (action === "confirm") {
        if (!this.AuditDesc) {
          this.$toast.fail("请填写驳回原因！");
          done(false);
        } else {
          this.$axios
            .post("/api/Proclamation/Audit", {
              AuditDesc: this.AuditDesc,
              AuditID: parseInt(this.id),
              AuditState: 4,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$toast.success(res.msg || "操作成功");
                this.AuditDesc = "";
                done();
                setTimeout(() => {
                  that.$router.replace("AuditSchedule");
                }, 1000);
              } else {
                this.$toast.fail(res.msg || "操作失败");
              }
            });
        }
      } else {
        this.AuditDesc = "";
        done();
      }
    },
  },
};
</script>
    <style lang="less" scoped>
@import "./index.less";
</style>
    